@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

body{
  font-family: 'Lato', sans-serif;
}

h5 {
  font-weight: bold;
}

.main-body { 
  max-width: 1150px;
  margin: 0 auto;
}

.jumbotron {
  background-image: url("images/landing-oceanfront-lounge.png");
  background-size: cover;
}

.jumbotron .main-box {
  max-width: 720px;
  border-radius: 20px !important;
  padding: 35px;
}

.jumbotron .register-for-free {
  font-weight: bold;
}

.jumbotron .how-it-works {
  font-size: 1.2em;
}

.main-callout {
  font-size: 35px;
}

section h4 {
  font-weight: bold;
  font-size: 1.6em;
}

section#hotel-and-villas,
section#jumbotron,
section#testimonials-section,
section#how-it-works {
  background-color: #eff6f9;
}

section#hotel-and-villas.logged-in {
  background-color: #ffffff;
}


section#jumbotron {
  padding: 0px;
}

section#hotel-and-villas,
section#movie-section,
section#testimonials-section,
section#theme-parks-section,
section#sports-section,
section#concerts-section,
section#signup-now {
  padding: 75px 0 0 0;
}


section#hotel-and-villas .ticket-container img {
  border-radius: 20px;
}

section#hotel-and-villas .blurb-overlay {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

section#hotel-and-villas .btn-city {
  font-size: 1.2em;
  font-weight: bold;
}

section#hotel-and-villas .blurb {
  font-size: 1.2em !important;  
}

section#theme-parks-section .ticket-container {
  margin: 0 auto;
}

section#theme-parks-section .ticket-container.logged-in {
  border: none;
}

section#theme-parks-section .ticket-container.logged-in img {
  border: none;
  border-radius: 10px;
}

section#theme-parks-section .ticket-overlay-container {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.view-all {
  font-size: 1.2em;
  padding-bottom: 10px;
}

.blurb {
  font-size: 17px;
}

.blurb .see-latest {
  font-weight: bold;
  color: #2d6b75;
  margin: 5px 0;
  font-size: 1.1em;
  cursor: pointer;
}

.how-it-works h3 {
  font-size: 2.2em;
  font-weight: bold;
}

.how-it-works ol li {
  font-size: 1.2em;
}

.movie-container {
  width: 150px;
  position: relative;
  border: solid 1px #2d6b75;
  border-radius: 5px;
}

.movie-logo {
  position: absolute;
  bottom: 0px;
  height: 55px;
  width: 100%;
  background-color: #ffffff;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.movie-poster {
  max-width: 110px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.movie-logo img {
  margin: 0 auto;
  max-width: 110px;
}

.movie-blurb {
  /* width: 160px; */
}

.ticket-container {
  position: relative;
  cursor: pointer;
  border: solid 1px #2d6b75;
  border-radius: 3px;
  /*min-width: 300px;
  width: 300px;*/
}

section#hotel-and-villas .ticket-container {
  border-radius: unset;
  border: unset;
}

section#testimonials-section {
  font-size: 1.2em;
}

.blurb-overlay {
  width: 100%;
  /*margin-top: -50px;*/
  background-color: #000000;
  opacity: 0.75;
  position: absolute;
  bottom: 0px;
}


.location {
  color: #ffffff;
  font-weight: 800;
  text-align: center;
}

.effective-dates {
  font-size: 0.80em
}

.pricing {
  border-radius: 50%;
  width: 100px; height: 100px;
  background-color: #f9ded4;
  text-align: center;
  padding: 15px;
  position: absolute;
  top: -10%; right: -15%;
}

.pricing .discount-price,
.pricing .percent-off {
  font-size: 1.3em;
  font-weight: 800;
  text-decoration: none;
  display: block;
}

.pricing .regular-price {
  font-size: 18px;
  text-decoration: line-through;
}

.movie-container img {
  border-radius: 3px;
  cursor: pointer;
}
.movie-container .pricing {
  right: -25%;
}

.pricing.hotel.regular {
  border-radius: 50%;
  width: 100px; height: 100px;
  background-color: #eff6f9;
  padding: 20px;
  border: solid 1px;
  top: 0; right: -5%;
  text-decoration: line-through !important;
}

.pricing.hotel.discounted {
  border-radius: 50%;
  width: 110px; height: 110px;
  background-color: #ffc700;
  padding: 25px 0 0 0;
  top: -20px; right: -5%;
}

.pricing.hotel.discounted .percent-off {
  font-size: 2.0em;

}

.pricing.hotel .small {
  font-size: 0.9em;
  display: block;
}

.ticket-container,
.ticket-poster {
  /*width: 320px;*/
}


.star-container {
  height: 25px;
  width: 125px;
  margin: 0 auto;
}

.star {
  width: 15px;
  height: 15px;
  background: url("images/star.png");
  background-repeat: no-repeat;
  background-size: contain;
  padding: 5px;
  float:left;
  opacity: 1;
  margin: 0 5px;
}

.testimonial {
  border: solid 1px #2d6b75;
  border-radius: 35px;
  padding: 25px;
  margin: 5px;
  min-height: 200px;
  font-style: italic;
  font-weight: bold;
  font-size: 1.1em;
}

.testimonial .star-container {
  width: 275px;
}

.testimonial .star {
  width: 45px;
  height: 45px;
  margin: 0 5px;
}

#signup-now {
  font-size: 1.3em;
}

.sign-up-now {
  font-size: 1.3em;
  font-weight: bold;
}

.ticket-overlay-container {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
  background-color: #ffffff;
  opacity: .75;
  width: 100%;
  height: 50%;
}

.ticket-overlay-container .ticket-overlay-title {
  font-weight: bold;
  font-size: 1.3em;
}

.ticket-overlay-container .ticket-overlay-subtitle {
  font-weight: normal;
  font-size: 1.2em;
}

.ticket-overlay-container .ticket-overlay-subtitle-2 {
  font-weight: normal;
  font-size: 1.0em;
}

.default-link {
  border: none;
  background: none;
  color: #2d6b75;
  font-weight: bold;
}

.themepark .ticket-overlay-container {
  top: unset;
  bottom: 0;
  height: 25%;
  background-color: #000000;
  border: none;
  color: #ffffff;
  text-align: center;
}

.clear {
  clear:both;
  float: none;
}

.clear-button {
  border: none;
  background: none;
  padding: 0;
  text-decoration: underline;
}

.text-black {
  color: #000000;
}

.text-larger {
  font-size: 1.1em;
}

div.ett-logo {
  width: 400px;
  height: 55px;
}

div.ett-logo img {
  width: 100%;
  height: 100%;
}

.menuitem {
  padding: 5px 5px 7px 5px;
}

#menu a {
  font: 19px/21px Oswald;
  word-wrap: normal;
  cursor: pointer;
  white-space: nowrap;
  line-height: 22px;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.4);
}

.smallmenu {
  line-height: 19px;
  font-size: 13px;
  text-transform: none;
}

.how-it-works-register {
  text-decoration: underline;
  color: #2d6b75;
  cursor: pointer;
}

.home-pricing {
  font-size: 1.2em;
  color: #ffffff;
  margin-bottom: 5px;
}

@media only screen and (max-width: 576px) {
  div.ett-logo {
    width: 270px;
    height: auto;
    margin-left: 5px;
  }

  .pricing.hotel.discounted {
    top: 25px;
  }
  
  section#sports-section .ticket-container,
  section#theme-parks-section .ticket-container,
  section#concerts-section .ticket-container {
    max-width: 275px;
    margin: 10px auto;
  }

  section#hotel-and-villas,
  section#movie-section,
  section#testimonials-section,
  section#theme-parks-section,
  section#sports-section,
  section#concerts-section,
  section#signup-now {
    padding: 25px 0 0 0;
  }  
}

.billed-footnote {
  font-size: 12px;
  font-weight: normal;
  color: #000000;
}

@media only screen and (min-width: 768px) {
  #menu-b {
    display: none;
  }
}

