$theme-colors: (
   "info": pink,
   "danger": red,
   "primary": #2d6b75,
   "tertiary": #f9ded4,
   "lightblue" : #eff6f9,
);

.btn.btn-lightblue:focus,
.btn.btn-lightblue:active,
.btn.btn-lightblue:active:hover,
.btn.btn-lightblue:hover {
   background-color: #2d6b75;
   color: #ffffff;
}

@import '~bootstrap/scss/bootstrap';


